import Swiper, { Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let touchSwiperWr = document.querySelectorAll('.touch-swiper-wr');
touchSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.touch-us__next');
    let prevEl = parentEl.querySelector('.touch-us__prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let touchForm = parentEl.querySelector('form.touch-us__form');
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Pagination],
      observer: true,
      observeParents: true,
      spaceBetween: 8,
      autoHeight: true,
      slidesPerView: 1,
      allowSlideNext: false,
      allowTouchMove: false,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
    // swiperObserver(swiper);

    touchForm.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    });

    nextEl.addEventListener('click', function (e) {
      e.preventDefault();

      const activeSlide = wrapper.querySelector('.swiper-slide-active');

      const input = activeSlide.querySelector('input');
      const select = activeSlide.querySelector('select');
      const errorMessage = activeSlide.querySelector('.error-message');

      if (select && activeSlide.getAttribute('data-step') == '1') {
        const option = select.querySelector('option');

        if (option.value === 'Hiring Talent') {
          load('page/solutions_popup');
          return;
        } else if (option.value === 'Looking for a new role') {
          load('page/jobs_popup');
          return;
        }
      }

      // Валідація для кожного степу
      if (input) {
        if (input.name === 'fullname' && input.value.trim() === '') {
          errorMessage.style.display = 'block';
          input.classList.add('input-error');
        } else if (input.name === 'email' && !validateEmail(input.value)) {
          errorMessage.style.display = 'block';
          input.classList.add('input-error');
        } else if (input.name === 'message' && input.value.trim() === '') {
          errorMessage.style.display = 'block';
          input.classList.add('input-error');
        } else {
          if (errorMessage) {
            errorMessage.style.display = 'none';
          }
          input.classList.remove('input-error');
          swiper.allowSlideNext = true; // Тимчасово дозволяємо перехід на наступний слайд
          swiper.slideNext(); // Переходимо на наступний слайд
          swiper.allowSlideNext = false; // Після переходу знову блокуємо
        }
      } else {
        swiper.allowSlideNext = true; // Тимчасово дозволяємо перехід на наступний слайд
        swiper.slideNext(); // Переходимо на наступний слайд
        swiper.allowSlideNext = false; // Після переходу знову блокуємо
      }
    });

    function validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    }
  }
});
