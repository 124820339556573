let marksCanvas = document.getElementById("breakdownRadar");

let initialData = {};

// const image = new Image();
// image.src = 'https://www.chartjs.org/img/chartjs-logo.svg';

// const ctx = document.getElementById('breakdownRadar').getContext('2d');
// const fillPattern = ctx.createPattern(image, 'repeat');

let marksData = {
  labels: ["Accuracy", "Speed", "Customisation", "Cost", "Reach", "Success", ["Candidate", "Experience"]],
  datasets: [
    {
      label: "Engage",
      // backgroundColor: "rgba(29, 216, 188, 0.3)",
      data: [2, 5, 2, 3, 3, 3, 3],
      borderColor: '#1DD8BD',
      pointBorderWidth: 1,
      pointRadius: 4,
      pointBackgroundColor: '#ffffff00',
      pointBorderColor: '#FFF',
      showLine: true,
      tension: 0,
      // backgroundColor: fillPattern,
    }, 
    {
      label: "Partner",
      backgroundColor: "rgba(0, 142, 219, 0.4)",
      data: [5, 4, 4, 3, 4, 5, 5],
      borderColor: '#0090DB',
      pointBorderWidth: 1,
      pointRadius: 4,
      pointBackgroundColor: '#ffffff00',
      pointBorderColor: '#FFF',
      showLine: true
    },
    {
      label: "Executive Search",
      backgroundColor: "rgba(247, 124, 72, 0.3)",
      data: [5, 3, 5, 4, 5, 5, 5],
      borderColor: '#F77D48',
      pointBorderWidth: 1,
      pointRadius: 4,
      pointBackgroundColor: '#ffffff00',
      pointBorderColor: '#FFF',
      showLine: true
    },
  ]
};

let radarChart = new Chart(marksCanvas, {
  type: 'radar',
  data: marksData,
  options: {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 600,
      easing: 'easeInOutQuart',
  },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        align: 'start',
      },
    },
    scale: {
      beginAtZero: true,
      max: 5,
      min: 0,
    },
    scales: {
      count: 1,
      suggestedMax: 5,
      suggestedMin: 0,
      r: {
        startAngle: -19,
        // бг для ліній
        backgroundColor: '#063159',
        grid: {
          lineWidth: 3,
          circular: true,
          offset: true,
          tickLength: 16,
          color: ['#06335A', '#083C60', '#094464', '#0A4A68', '#083C60'],
          // z: 1,
        },
        // лінії від центру до країв
        angleLines: {
          display: false,
        },
        // назви на краях та точки
        pointLabels: {
          display: true,
          font: {
            size: window.innerWidth > 520 ? 15 : 12, 
            family: 'Poppins', 
            weight: 'bold'
          },
          color: '#fff', 
          padding: 15,
        },
        // angleLines цифри
        ticks: {
          stepSize: 1,
          display: false,
        },
      }
    },
  }
});

radarChart.data.datasets.forEach((dataset, index) => {

  initialData[dataset.label] = {
      index: index,
      backgroundColor: dataset.backgroundColor,
      borderColor: dataset.borderColor,
      label: dataset.label,
  };
});

let activeLabel = null;
let hoverLabel = null;

const toggleButtons = document.querySelectorAll('.breakdown-tab');

toggleButtons.forEach(button => {
    button.addEventListener('click', function() {
        const datasetLabel = this.getAttribute('data-dataset');
        
        if (activeLabel === datasetLabel) {
          button.classList.remove('active');
          radarChart.data.datasets.forEach((dataset, index) => {
              dataset.backgroundColor = initialData[dataset.label].backgroundColor;
              dataset.borderColor = initialData[dataset.label].borderColor;
              dataset.order = index;
          });
          activeLabel = null;
        } else {
          toggleButtons.forEach(b => b.classList.remove('active'));
          button.classList.add('active');
          
          radarChart.data.datasets.forEach((dataset) => {
              if (dataset.label != datasetLabel) {
                  dataset.backgroundColor = 'rgba(146, 205, 235, 0.1)';
                  dataset.borderColor = 'rgba(21, 145, 233, 0.01)';
                  dataset.order = 1;
              } else {
                  dataset.backgroundColor = initialData[dataset.label].backgroundColor;
                  dataset.borderColor = initialData[dataset.label].borderColor;
                  dataset.order = 3;
              }
          });
          
          activeLabel = datasetLabel;
        }
        radarChart.update();
    });

    // Додати обробники подій для ховера (mouseenter і mouseleave)
    button.addEventListener('mouseenter', function() {
        const datasetLabel = this.getAttribute('data-dataset');

        // Якщо активний графік уже вибраний, не міняємо стан
        if (activeLabel) return;

        hoverLabel = datasetLabel;
        
        // Змінюємо кольори при ховері
        radarChart.data.datasets.forEach((dataset) => {
            if (dataset.label != datasetLabel) {
                dataset.backgroundColor = 'rgba(146, 205, 235, 0.1)';
                dataset.borderColor = 'rgba(21, 145, 233, 0.01)';
                dataset.order = 1;
            } else {
                dataset.backgroundColor = initialData[dataset.label].backgroundColor;
                dataset.borderColor = initialData[dataset.label].borderColor;
                dataset.order = 3;
            }
        });
        radarChart.update();
    });

    button.addEventListener('mouseleave', function() {
        // Якщо є активний графік після кліка, не скидаємо стан
        if (activeLabel) return;

        hoverLabel = null;
        
        // Повертаємо до дефолтного стану
        radarChart.data.datasets.forEach((dataset, index) => {
            dataset.backgroundColor = initialData[dataset.label].backgroundColor;
            dataset.borderColor = initialData[dataset.label].borderColor;
            dataset.order = index;
        });
        radarChart.update();
    });
});